.job_box {
  background: #2f61c3;
  padding: 2rem;
  color: white;
  margin: 1rem 0;
  cursor: pointer;
}
.job_head {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.job_title {
  font-size: x-large;
  font-family: "Raleway";
}
.job_body {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.job_lable {
  font-weight: 300;
  font-family: "Raleway";
}
.apply {
  padding: 5rem 0;
}
.apply input[type="text"] {
  /* margin: 2rem; */
  width: 100%; /* Full width */
  padding: 10px; /* Padding inside the input */
  border: 1px solid #ccccccb5; /* Light gray border */
  border-radius: 10px; /* Rounded corners */
  font-size: 16px; /* Font size */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  background: transparent;
  color: white;
  font-family: "Raleway";
}
.apply input[type="email"] {
  /* margin: 2rem; */
  width: 100%; /* Full width */
  padding: 10px; /* Padding inside the input */
  border: 1px solid #ccccccb5; /* Light gray border */
  border-radius: 10px; /* Rounded corners */
  font-size: 16px; /* Font size */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  background: transparent;
  color: white;
  font-family: "Raleway";
}
.apply input[type="number"] {
  /* margin: 2rem; */
  width: 100%; /* Full width */
  padding: 10px; /* Padding inside the input */
  border: 1px solid #ccccccb5; /* Light gray border */
  border-radius: 10px; /* Rounded corners */
  font-size: 16px; /* Font size */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  background: transparent;
  color: white;
  font-family: "Raleway";
}
.apply input[type="file"] {
  /* margin: 2rem; */
  width: 100%; /* Full width */
  padding: 10px 10px 10px 0; /* Padding inside the input */

  font-size: 16px; /* Font size */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  background: transparent;
  color: white;
  font-family: "Raleway";
}

/* Style the input field on focus */
.apply input[type="text"]:focus {
  border-color: #66afe9; /* Change border color on focus */
  outline: none; /* Remove default outline */
  box-shadow: 0 0 8px rgba(102, 175, 233, 0.6); /* Add a subtle shadow on focus */
}
.apply input[type="email"]:focus {
  border-color: #66afe9; /* Change border color on focus */
  outline: none; /* Remove default outline */
  box-shadow: 0 0 8px rgba(102, 175, 233, 0.6); /* Add a subtle shadow on focus */
}
.apply-head {
  font-size: 50px;
  font-family: "Raleway";
}
.apply-body {
  font-size: 18px;
  font-family: "Raleway";
}

.custom-choose {
  width: 100%; /* Full width */
  padding: 10px; /* Padding inside the input */

  font-size: 16px; /* Font size */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  background: transparent;
  color: white;
  font-family: "Raleway";
}
.apply-main {
  margin: 0 auto;
  max-width: 900px;
}
.apply label {
  width: 100%;
}
.apply-textarea {
  width: 97%;
  background: transparent;
  border: 1px solid #ccccccb5;
  border-radius: 10px;
  color: white;
  min-height: 20px;
  padding: 10px;
  font-size: 16px;
  font-family: "Raleway";
}
.apply-form-field {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}
.submit-form-button{
  border: none;
  background: white;
  border-radius: 10px;
  font-weight: bold;
  height: min-content;
  padding: 1rem;
  cursor: pointer;
}
.top-jd{
  display: flex;
  justify-content: space-between;
  padding: 6rem 6rem 2rem 6rem;
  align-items: center;
}
.bottom-jd{
padding: 0rem 6rem 5rem 6rem; text-align: justify;
}
.error-message{
  font-size: x-small;
  color: red;
}
@media screen and (max-width: 800px) {
  .top-jd{
    display: flex;
    justify-content: space-between;
    padding: 6rem 2rem 2rem 2rem;
    align-items: center;
  }
  .bottom-jd{
  padding: 0rem 2rem 2rem 2rem; text-align: justify;
  }
  .apply-main {
    padding: 2rem;
  }
  .apply-body {
    font-size: 14px;
    font-family: "Raleway";
  }
  .apply-head {
    font-size: 24px;
    font-family: "Raleway";
  }
  .job_box {
    padding: 1rem;
  }
  .job_title {
    font-size: large;
    font-family: "Raleway";
  }
  .apply-textarea {
    width: 94%;
    background: transparent;
    border: 1px solid #ccccccb5;
    border-radius: 10px;
    color: white;
    min-height: 20px;
    padding: 10px;
    font-size: 16px;
    font-family: "Raleway";
  }
  .apply-form-field {
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;
  }
}
