.component_3{
    /* position: sticky; */
    padding: 5rem ;
    max-width: 1000px;
    margin: 0 auto;
    display: block;
    /* display: flex;
    justify-content: space-between; */
}
.left{
    width: 40%;
    /* position: sticky; */
    /* height: 200vh; */
}
.left-head{
    padding-top: 65px;
    padding-bottom: 25px;
    color: rgb(191, 47, 74);
    font-size: 65px;
    font-family: "Raleway";
    line-height: 55px;
    /* line-gap-override: ; */
}
.vertical{
    margin-top: 50px;
    height: 900px;
    width: 2px;
    background-color: #2F61C3;
}
.right-items{
    display: grid;
    color: #2F61C3;
    font-size: 35px;
    padding-bottom: 2rem;
    /* gap: 1rem; */
    font-family: "Raleway";
}
.centered-content {
    font-size: 18px;
    color: black;
    font-family: "Raleway";
    /* padding-left: 1rem; */
    /* text-align: center; */
}
.centered-content1 {
    font-size: x-large;
    color: black;
    font-family: "Raleway";
    /* padding-left: 1rem; */
    /* text-align: center; */
}

.centered-content img {
    display: inline-block;
    vertical-align: middle;
    padding-right: 1rem;

}
.centered-content1 img {
    display: inline-block;
    vertical-align: middle;
    padding-right: 1rem;

}
.right{

    padding-top: 80px;
    /* display: grid;
    gap: 1rem; */
}
.right-flex{
    display: flex;
    justify-content: space-around;
    padding-left: 40%;
    /* position: sticky;
    height: 200vh; */
}

article {
    /* background-image: linear-gradient(
      to bottom,
      transparent 50px,
      #fdef67 50px,
      #fdef67 calc(100% - 50px),
      transparent 0
    ); */
    margin: auto auto 50px auto;
  }
  .category,
  .title,
  .footer {
    position: -webkit-sticky;
    position: sticky;
    height: 50px;
  }
  .category {
    top: 0;
    font: 41px/57px "Titan One";
    font-family: "Raleway";
    color: #f83d23;
    text-shadow: 5px 5px 0 dodgerblue;
  }
  .title {
    top: 0;
    width: 40%;
    /* font: 50px/60px "Crimson Text";
    color: #fdef67;
    text-shadow: 2px 2px 0 #f83d23;
    background-color: dodgerblue;
    margin: 0; */
  }
  .image_3{
     width: 100%;
}
.c3-mobile{
    display: none;
}
.left-m-dec{
    font-size: large;
    font-family: "Raleway";
    text-align: justify;
}
@media screen and (max-width:800px) {
    .component_3{
        display: none;
    }
    .c3-mobile{
        display: block;
        padding: 5rem 2rem;
    }
    .left-head{
        font-size: x-large;
        font-family: "Raleway";
        padding: 0;
    }
    .right-items{
        display: grid;
        color: #2F61C3;
        font-size: large;
        font-family: "Raleway";
        padding-bottom: 1rem;
        /* gap: 1rem; */
    }
    .centered-content {
        font-size: 12px;
        color: black;
        font-family: "Raleway";
        /* padding-left: 1rem; */
        /* text-align: center; */
    }
    .left-m-dec{
        font-size: small;
        font-family: "Raleway";
        padding-bottom: 2rem;
        text-align: justify;

    }
    .c3-m-h{
        display: none;
    }
    .centered-content1 {
        font-size: small;
        font-family: "Raleway";
        color: black;
        /* padding-left: 1rem; */
        /* text-align: center; */
    }
  }