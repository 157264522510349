.comp_2_heading {
  /* color: #2f61c3; */
  color: rgb(191, 47, 74);

  font-weight: bold;
  font-size: medium;
  font-family: "Raleway";
}
.comp_2_desc {
  text-align: justify;
  font-size: x-large;
  font-family: "Raleway";
}
.comp_2_desc_dev {
  text-align: justify;
  font-size: x-large;
  font-family: "Raleway";
}
.comp_2 {
  padding: 4rem 2rem 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}
.image_3 {
  width: -webkit-fill-available;
  display: block;
}
.grid-head {
  text-align: center;
  font-size: 35px;
  font-family: "Raleway";
  padding: 5rem 2rem;
}
.grid-m-img {
    display: none;
  }
  .grid-w-img {
    width: 50%;
    display: flex;
    margin: 0 auto;
  }
  .comp_2_dev {
    padding: 3rem 2rem 0 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
@media screen and (max-width: 800px) {
  .comp_2_desc {
    /* text-align: justify; */
    font-size: medium;
    font-family: "Raleway";
  }
  .grid-head {
    text-align: center;
    font-size: large;
    font-family: "Raleway";
    padding: 5rem 2rem;
  }
  .grid-m-img {
    width: 50%;
    display: flex;
    margin: 0 auto;
  }
  .grid-w-img {
    display: none;
  }
  .comp_2 {
    padding: 4rem 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  .comp_2_desc_dev {
    text-align: justify;
    font-size: medium;
    font-family: "Raleway";
  }
  .comp_2_dev {
    padding-top: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
}
.table-container {
  overflow-x: auto; /* Enable horizontal scrolling on small screens */
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for a subtle elevation effect */
}

/* Styling for the main table */
.responsive-table {
  width: 100%;
  min-width: 1000px; /* Ensure table is wider than screen on small devices */
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
}

.responsive-table thead tr {
  background-color: rgb(191, 47, 74); /* Header background color */
  color: #fff;
}

.responsive-table th, .responsive-table td {
  padding: 12px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  border-bottom: 1px solid #ddd;
}

.responsive-table tbody tr:nth-child(even) {
  background-color: #f9f9f9; /* Alternating row background */
}

.responsive-table tbody tr:nth-child(odd) {
  background-color: #fff;
}

