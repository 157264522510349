.logo-rotator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  
  .logo-rotator img {
    width: 20%;
    transition: opacity 0.5s ease-in-out;
  }
  
  .logo-rotator img.active {
    opacity: 1;
  }
  .image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 400px;  */
  }
  
  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures image covers the entire container */
  }
  
  .centered-text {
    position: absolute;
    color: white;
    font-size: 2rem;
    text-align: center;
  }
  @media screen and (max-width:1000px) {
    .centered-text {
      position: absolute;
      color: white;
      font-size: medium;
      text-align: center;
    }
  }