.navbar {
  width: -webkit-fill-available;
  display: flex;
  flex-direction: row; /* Added */
  justify-content: space-between;
  padding: 1rem 2rem;
  /* margin: 1rem 4rem; */
  align-items: center;
  background-color: rgba(255, 255, 255); /* White background with slight transparency */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 99999;
  position: fixed;
}
.explore-button {
  background: #c32f49;
  border-radius: 25px;
  border: none;
  color: white;
  font-weight: bold;
  font-family: "Raleway";
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  cursor: pointer;
}
.fullscreen-overlay {
    /* padding: 2rem 6rem; */
  z-index: 999999999999;
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.7
  ); /* Adjust the alpha value for transparency */
  backdrop-filter: blur(10px); /* Adjust the blur radius as needed */
  border-radius: 10px;
}
.over-flex{
    width: -webkit-fill-available;
  display: flex;
  flex-direction: row; /* Added */
  justify-content: space-between;
  padding: 1rem 2rem;
  margin: 1rem 4rem;
  align-items: center;
}
.nav-items{
    display: grid;
    justify-items: end;
    padding-top: 5rem;
    padding-right: 6rem;
    gap: 1rem;
}
.nav-item-cs{
    /* font-weight: bold; */
    font-size: 35px;
    font-family: "Raleway";
    cursor: pointer;
}
.nav-subitem-cs{
  text-align: right;
    font-size: larger;
    font-family: "Raleway";
    cursor: pointer;
}
.nav-item-cs:hover{
    /* font-weight: bold; */
    font-size: 35px;
    color: #c32f49;
    font-family: "Raleway";
}
.nav-subitem-cs:hover{
  /* font-weight: bold; */
  font-size: larger;
  color: #c32f49;
  font-family: "Raleway";
}

.nav-right-buttons{
  display: flex;
}
@media screen and (max-width:800px) {
  .navbar {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row; /* Added */
    justify-content: space-between;
    /* padding: 1rem ; */
    margin: 0;
    align-items: center;
    background: white;
    position: fixed;
  }
  .nav-logo{
    width: 150px;
  }
  .explore-button {
    background: #c32f49;
    border-radius: 25px;
    border: none;
    color: white;
    font-weight: bold;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    font-size: 10px;
    font-family: "Raleway";
  }
  .nav-right-buttons{
    display: flex;
  }
  .ham{
    width:25px;
  }
  .over-flex{
    width: -webkit-fill-available;
  display: flex;
  flex-direction: row; /* Added */
  justify-content: space-between;
  padding: 1rem 2rem;
  margin: 0;
  align-items: center;
}
.nav-items{
    display: grid;
    justify-items: end;
    padding-top: 5rem;
    padding-right: 2rem;
    gap: 1rem;
}
.nav-item-cs{
    /* font-weight: bold; */
    font-size: 24px;
    cursor: pointer;
    font-family: "Raleway";
}
.nav-item-cs:hover{
    /* font-weight: bold; */
    font-size: 24px;
    color: #c32f49;
    font-family: "Raleway";
}
}